<template>
  <div v-if="!isLoading">
    <layout v-if="!mobile">
      <div style="height: 173px"/>
      <div class="header flex items-center">
        <div class="pool-logo">
          <img :src="earnImg"/>
        </div>
        <div class="pool-name">{{ poolName }}</div>
        <a :href="$store.state.aboutProject[poolName].linkc" target="_blank">
          <img class="contact-icon" src="../../../public/images/igo/icon_contact_tele.png"/>
        </a>
        <a :href="$store.state.aboutProject[poolName].linkb" target="_blank">
          <img class="contact-icon" src="../../../public/images/igo/icon_contact_twitter.png"/>
        </a>
        <a :href="$store.state.aboutProject[poolName].linkd" target="_blank">
          <img class="contact-icon" src="../../../public/images/igo/icon_contact_email.png"/>
        </a>
        <a :href="$store.state.aboutProject[poolName].linka" target="_blank">
          <img class="contact-icon" src="../../../public/images/igo/icon_contact_medium.png"/>
        </a>
      </div>
      <div style="height: 32px"/>
      <div class="info-panel flex items-center justify-between">
        <div>
          <div class="pool-remark">
            <div>{{ earn }} Pool</div>
            <div class="label">{{ poolStatus }}</div>
          </div>
          <div style="height: 22px"/>
          <div class="pool-desc">
            {{ introduce }}
          </div>
          <div style="height: 22px"/>
          <div class="flex items-center">
            <a :href="'https://bscscan.com/address/' + earnToken" target="_blank"
               v-if="isLink && poolStatus === 'CLOSED'">
              Official Token Contract
            </a>
            <a v-else>
              Official Token Contract
            </a>
            <a :href="'https://bscscan.com/address/' + poolContract" target="_blank">
              IGO Swap Contract
            </a>
            <a v-if="claimUrl" :href="claimUrl" target="_blank">Claim</a>
          </div>
        </div>
        <div class="progress-panel">
          <div class="title">
            <div>{{
                poolContract === '0xB23C1293e17b8E3ba6CD69fA1122f736487C40c5' ? 'Swap Amount' : 'Swap Ratio'
              }}
            </div>
            <div v-if="priceText === ''">
              1 {{ lpType }} = {{ lpPrice }} {{ earn }}
            </div>
            <div v-else>
              {{ priceText }}
            </div>
          </div>
          <div style="height: 24px"/>
          <div class="horizontal-line"/>
          <div style="height: 19px"/>
          <div class="progress-title">Swap Progress</div>
          <div style="height: 14px"/>
          <el-progress :text-inside="true" :stroke-width="15" :percentage="percentage" color="#05F1A9"></el-progress>
          <div style="height: 12px"/>
          <div class="progress-title flex justify-between"><span>0%</span><span>100%</span></div>
        </div>
      </div>
      <div style="height: 100px"/>
      <div class="main-panel relative">
        <div class="block-mask"
             v-if="showWhiteListMask">
          <div class="tips">You are not whitelisted
            <el-tooltip placement="top">
              <div slot="content"
                   style="font-size: 15px;
                             font-weight: 500;
                             color: #FFFFFF;
                             line-height: 20px;">
                <div style="font-size: 17px;
                                font-weight: 500;
                                color: #FB8F10 !important;
                                line-height: 20px;"
                >Access Type:
                </div>
                <br/>
                1. Whitelisted Gamers without Staking tokens.
                <br/>
                <template v-if="poolThresholdArray.length > 0 && igoType === 'many'">
                  <div v-for="(obj, index) in poolThresholdArray" :key="index">
                    {{ index + 2 }}. You can choose to mortgage {{ obj }} {{ poolObj[index].poolCcurrency }} in the pool
                    {{ poolObj[index].poolName }} to qualify
                  </div>
                </template>
                <div v-if="igoType === 'one'">
                  2. Holders who stake at least {{ threshold }} {{ earn }} in Farms.
                </div>
                <br/>
              </div>
              <div class="why-btn">?</div>
            </el-tooltip>
          </div>
        </div>
        <div :style="{ opacity: showWhiteListMask ? 0.3 : 1}">
          <div style="height: 48px"/>
          <div class="title">WHITELISTED GAMER / QUALIFIED STAKER</div>
          <div style="height: 46px"/>
          <div class="flex items-center justify-between">
            <div class="flex items-center" style="width: 70%">
              <span>Commit Your Funds:</span>
              <div class="amount-input">
                <input placeholder="Please enter the exchange amount" type="number" v-model="amount"/>
                <div class="max-btn" @click="setMax()">MAX</div>
              </div>
              <span>{{ lpType }}</span>
            </div>
            <div class="act-btn-disabled" :class="{'enabled': isStart && !isEnd}" v-if="owanceNum > 0" @click="join()">
              Join
            </div>
            <div class="act-btn-disabled" :class="{'enabled': isStart && !isEnd}" v-else
                 @click="coinApproveFundRaising()">
              Approve
            </div>
          </div>
          <div style="height: 48px"/>
          <div class="flex items-center">
            <div style="margin-right: 40px">Balance: {{ balance }} {{ lpType }}</div>
            <div>Allocation: {{ availableAuota }} / {{ quota }} {{ lpType }}</div>
          </div>
        </div>
        <div style="height: 73px"/>
        <div class="flex justify-between">
          <div class="flex items-center">Your Token To Be Claimed In Total:
            <div class="token-to-claim">{{ obtain }}</div>
          </div>
          <div v-if="poolContract !== '0x6bBf952E24808c81f78F30aB0f76dE9062985066' && isOpenClaim"
               :class="(isClaim && !isUserClaim) ? 'act-btn-disabled enabled' : 'act-btn-disabled'"
               @click="claim(false)">
            Claim
          </div>
        </div>
        <div style="height: 28px"/>
      </div>
      <div style="height: 83px"/>
      <div class="detail-title">POOL DETAILS</div>
      <div style="height: 35px"/>
      <div class="flex items-center">
        <div class="detail-panel">
          <div class="title">Pool Information</div>
          <div class="info-row" v-for="(info, idx) in poolInfoList" :key="idx">
            <div class="label">{{ info.label }}</div>
            <div class="value">{{ info.value }}</div>
          </div>
        </div>
        <div class="detail-panel" style="width: 60%">
          <div class="title">Token Information</div>
          <div class="info-row" v-for="(info, idx) in tokenInfoList" :key="idx">
            <div class="label">{{ info.label }}</div>
            <div class="value">{{ info.value }}</div>
          </div>
        </div>
      </div>
      <div style="height: 87px"/>
      <div class="detail-title">About the Project</div>
      <div style="height: 24px"/>
      <div class="info-panel flex items-center justify-center" style="height: auto; padding: 15% 0">
        <div class="about-project-text">
          {{ $store.state.aboutProject[poolName].introduce }}
        </div>
      </div>
      <div style="height: 87px"/>
      <div class="detail-title">Schedule</div>
      <div style="height: 28px"/>
      <div class="schedule-row" v-for="(obj, index) in $store.state.schedule[poolName]" :key="index">
        <div class="row-one">
          {{ obj.round }}
        </div>
        <div class="row-opens">{{ obj.opens }}</div>
        <div class="row-one">{{ obj.closes }}</div>
      </div>
      <div style="height: 82px"/>
    </layout>
    <mobile-layout v-else>
      <div style="height: 1.52rem"/>
      <div class="m-pool-logo">
        <img :src="earnImg"/>
      </div>
      <div style="height: 0.24rem"/>
      <div class="m-pool-name">{{ poolName }}</div>
      <div style="height: 0.22rem"/>
      <div class="flex justify-center items-center">
        <a :href="$store.state.aboutProject[poolName].linkc" target="_blank">
          <img class="m-contact-icon" src="../../../public/images/igo/icon_contact_tele.png" style="margin-left: 0"/>
        </a>
        <a :href="$store.state.aboutProject[poolName].linkb" target="_blank">
          <img class="m-contact-icon" src="../../../public/images/igo/icon_contact_twitter.png"/>
        </a>
        <a :href="$store.state.aboutProject[poolName].linkd" target="_blank">
          <img class="m-contact-icon" src="../../../public/images/igo/icon_contact_email.png"/>
        </a>
        <a :href="$store.state.aboutProject[poolName].linka" target="_blank">
          <img class="m-contact-icon" src="../../../public/images/igo/icon_contact_medium.png"/>
        </a>
      </div>
      <div style="height: 0.37rem"/>
      <div class="m-info-panel mx-auto">
        <div style="height: 0.5rem"/>
        <div class="pool-remark">
          <div>{{ earn }} Pool</div>
          <div class="label">{{ poolStatus }}</div>
        </div>
        <div style="height: 0.31rem"/>
        <div class="pool-desc">
          {{ introduce }}
        </div>
        <div style="height: 0.2rem"/>
        <a :href="'https://bscscan.com/address/' + earnToken" target="_blank"
           v-if="isLink && poolStatus === 'CLOSED'"
           style="margin-bottom: 0.18rem"
        >
          Official Token Contract
        </a>
        <a v-else style="margin-bottom: 0.18rem">
          Official Token Contract
        </a>
        <a :href="'https://bscscan.com/address/' + poolContract" target="_blank">
          IGO Swap Contract
        </a>
        <a v-if="claimUrl" :href="claimUrl" target="_blank" style="margin-top: 0.18rem">Claim</a>
        <div style="height: 0.29rem"/>
        <div class="progress-panel">
          <div style="height: 0.14rem"/>
          <div class="title">
            <div>{{
                poolContract === '0xB23C1293e17b8E3ba6CD69fA1122f736487C40c5' ? 'Swap Amount' : 'Swap Ratio'
              }}
            </div>
            <div v-if="priceText === ''">
              1 {{ lpType }} = {{ lpPrice }} {{ earn }}
            </div>
            <div v-else>
              {{ priceText }}
            </div>
          </div>
          <div style="height: 0.29rem"/>
          <div class="horizontal-line"/>
          <div style="height: 0.12rem"/>
          <div class="progress-title">Swap Progress</div>
          <div style="height: 0.26rem"/>
          <el-progress :text-inside="true" :stroke-width="15" :percentage="percentage" color="#05F1A9"
                       style="height: 0.21rem; font-size: 0"></el-progress>
          <div style="height: 0.08rem"/>
          <div class="progress-title flex justify-between"><span>0%</span><span>100%</span></div>
        </div>
        <div style="height: 0.49rem"/>
      </div>
      <div style="height: 0.12rem"/>
      <div class="m-main-panel relative">
        <div class="block-mask"
             v-if="showWhiteListMask">
          <div class="tips">You are not whitelisted
            <el-tooltip placement="top">
              <div slot="content"
                   style="font-size: 0.15rem;
                             font-weight: 500;
                             color: #FFFFFF;
                             line-height: 0.2rem;">
                <div style="font-size: 0.17rem;
                                font-weight: 500;
                                color: #FB8F10 !important;
                                line-height: 0.2rem"
                >Access Type:
                </div>
                <br/>
                1. Whitelisted Gamers without Staking tokens.
                <br/>
                <template v-if="poolThresholdArray.length > 0 && igoType === 'many'">
                  <div v-for="(obj, index) in poolThresholdArray" :key="index">
                    {{ index + 2 }}. You can choose to mortgage {{ obj }} {{ poolObj[index].poolCcurrency }} in the pool
                    {{ poolObj[index].poolName }} to qualify
                  </div>
                </template>
                <div v-if="igoType === 'one'">
                  2. Holders who stake at least {{ threshold }} {{ earn }} in Farms.
                </div>
                <br/>
              </div>
              <div class="why-btn">?</div>
            </el-tooltip>
          </div>
        </div>
        <div :style="{ opacity: showWhiteListMask ? 0.3 : 1}">
          <div style="height: 0.18rem"/>
          <div class="title">WHITELISTED GAMER / QUALIFIED STAKER</div>
          <div style="height: 0.2rem"/>
          <div class="text">Commit Your Funds:</div>
          <div style="height: 0.16rem"/>
          <div class="amount-input">
            <input placeholder="Please enter the exchange amount" type="number" v-model="amount"/>
          </div>
          <div style="height: 0.26rem"/>
          <div class="flex items-center justify-center">
            <div class="max-btn" @click="setMax()">MAX</div>
            <div class="text" style="margin-left: 0.26rem; font-weight: 500;">{{ lpType }}</div>
          </div>
          <div style="height: 0.2rem"/>
          <div class="act-btn-disabled" :class="{'enabled': isStart && !isEnd}" v-if="owanceNum > 0" @click="join()">
            Join
          </div>
          <div class="act-btn-disabled" :class="{'enabled': isStart && !isEnd}" v-else
               @click="coinApproveFundRaising()">
            Approve
          </div>
          <div style="height: 0.2rem"/>
          <div class="text">Balance: {{ balance }} {{ lpType }}</div>
          <div style="height: 0.11rem"/>
          <div class="text">Allocation: {{ availableAuota }} / {{ quota }} {{ lpType }}</div>
        </div>
        <div style="height: 0.28rem"/>
        <div class="flex items-center justify-between" style="padding: 0 0.12rem">
          <div style="font-size: 0.12rem">Your Token To Be Claimed In Total:</div>
          <div class="token-to-claim">{{ obtain }}</div>
        </div>
        <div v-if="poolContract !== '0x6bBf952E24808c81f78F30aB0f76dE9062985066' && isOpenClaim"
             :class="(isClaim && !isUserClaim) ? 'act-btn-disabled enabled' : 'act-btn-disabled'"
             style="margin-top: 0.16rem"
             @click="claim(false)">
          Claim
        </div>
        <div style="height: 0.2rem"/>
      </div>
      <div style="height: 0.38rem"/>
      <div class="m-detail-title">POOL DETAILS</div>
      <div class="m-detail-panel">
        <div class="title">Pool Information</div>
        <div class="info-row" v-for="(info, idx) in poolInfoList" :key="idx">
          <div class="label">{{ info.label }}</div>
          <div class="value">{{ info.value }}</div>
        </div>
      </div>
      <div class="m-detail-panel">
        <div class="title">Token Information</div>
        <div class="info-row" v-for="(info, idx) in tokenInfoList" :key="idx">
          <div class="label">{{ info.label }}</div>
          <div class="value">{{ info.value }}</div>
        </div>
      </div>
      <div style="height: 0.38rem"/>
      <div class="m-detail-title">About the Project</div>
      <div class="m-info-panel flex items-center justify-center mx-auto" style="height: auto; padding: 0.5rem 0">
        <div class="about-project-text">
          {{ $store.state.aboutProject[poolName].introduce }}
        </div>
      </div>
      <div style="height: 0.38rem"/>
      <div class="m-detail-title">Schedule</div>
      <div style="height: 0.2rem"/>
      <div class="m-schedule-row" v-for="(obj, index) in $store.state.schedule[poolName]" :key="index">
        <div class="row-one">
          {{ obj.round }}
        </div>
        <div class="row-opens">{{ obj.opens }}</div>
        <div class="row-one">{{ obj.closes }}</div>
      </div>
      <div style="height: 82px"/>
    </mobile-layout>
  </div>
</template>

<script>
import {setDecimal,} from '../../tools/toolUtils.js';
import {BigNumber, BigNumberDiv, cutAccount} from '../../tools/index.js';
import {mixin1} from "../../tools/mixins";
import * as metaMaskTools from "../../tools/chain";
import {getFundEndingTime} from "../../tools/chain";
import Layout from "@/components/v2/Layout";
import isMobile from "@/consts/isMobile";
import MobileLayout from "@/components/v2/MobileLayout";

export default {
  name: "PoolInfo",
  components: {MobileLayout, Layout},
  mixins: [mixin1],
  data() {
    return {
      mobile: isMobile(),
      claimUrl: '',
      isLoading: false,
      accounts: null,
      activeName: 'first',
      poolName: null,
      igoType: 'one',
      isOverRaising: true,
      allowance: 0, // 还差多少钱筹满
      poolContract: null,
      poolStatus: null,
      refundPeriod: false,
      ifWhiteList: false,
      isAdmin: false,
      isWhite: 0,
      isPublic: false, // 开放池子，不需要门槛也不需要白名单
      isStandard: false,
      isUserClaim: false,
      isSureClaim: true,
      isOpenClaim: true,
      isStart: false,
      isEnd: false,
      poolRemainingTime: 0,
      isClaim: false,
      earnToken: '',
      earn: '',
      earnImg: '',
      isPoolRefund: false,
      isLink: true,
      introduce: '',
      lpType: '',
      lpToken: null,
      amount: null,
      owanceNum: 0,
      percentage: 0,
      lpPrice: 0,
      priceText: '',
      hardCap: 0,
      threshold: 0, // 门槛
      proportion: 0, // 个人兑换占比
      obtain: 0, // (预计)获取
      exchange: 0, // 兑换成功
      retrievable: 0, // 可取回
      balance: 0, // 余额
      quota: 0, // 额度
      availableAuota: 0, // 可用额度
      numberParticipants: 0,
      totalAmountInvested: 0,
      poolAddressArray: [],
      poolThresholdArray: [],
      poolObj: [
        {
          poolName: 'TFI-TFI-01',
          poolCcurrency: 'TFI'
        },
        {
          poolName: 'TFI-TFI-02',
          poolCcurrency: 'TFI'
        }
      ],
      poolInfoList: [],
      tokenInfoList: [],
    };
  },
  computed: {
    showWhiteListMask() {
      return !this.isPublic && (this.isStandard ? false : (parseInt(this.isWhite) === 0 || parseInt(this.isWhite) === 3))
    }
  },
  watch: {
    async isEnd(newVal) {
      if (newVal) {
        try {
          const endTime = await getFundEndingTime(this.poolContract);
          if (endTime * 1000 + 3600 * 24 * 3 * 1000 < Date.now()) {
            console.error('past 72 hours')
            this.percentage = 100;
            this.totalAmountInvested = this.hardCap;
            const getLastDigits = () => {
              const addrList = this.poolContract.split('')
              for (let i = addrList.length - 1; i >= 0; i--) {
                if (!isNaN(Number(addrList[i]))) {
                  return Number(addrList[i])
                }
              }
              return 0
            }
            const magicDigits = getLastDigits()
            this.numberParticipants = Number(this.numberParticipants) + 80 + magicDigits;
          }
        } catch (e) {
          // do nothing
          console.error('get end funding time error', e)
        }
      }
    }
  },
  async created() {
    if (this.mobile) {
      document.documentElement.style.fontSize =
          document.documentElement.clientWidth / 4.14 + "px";
    }
    this.isLoading = true;
    setTimeout(() => {
      this.onAccountsChanged();
    }, 1000)

    if (localStorage.getItem('accounts')) {
      this.accounts = localStorage.getItem('accounts')
    }
    await this.$store.dispatch('getRemotePoolConfig', this.accounts);
    await this.$store.dispatch('getRemoteClaimConfig').catch();

    if (sessionStorage.getItem('poolIndex')
        &&
        sessionStorage.getItem('poolContract')
        &&
        parseInt(this.$route.query.poolIndexNum) === parseInt(sessionStorage.getItem('poolIndex'))
    ) {
      this.initSetPoolDetail();
    }
    this.initPoolInfo()
    this.setPoolStatus()
    const poolDetailInfo = this.$store.state.poolDetails[this.poolName];
    this.poolInfoList = [
      {
        label: 'Token Distribution',
        value: poolDetailInfo["poolInformation"].TokenDistribution
      },
      {
        label: 'Total Funds Swapped',
        value: poolDetailInfo['poolInformation'].TotalFundsSwapped ? `${this.totalAmountInvested} ${this.lpType}` : ''
      },
      {
        label: 'Total Users Participated',
        value: poolDetailInfo['poolInformation'].TotalUsersParticipated ? this.numberParticipants : ''
      },
      {
        label: 'Hard Cap',
        value: poolDetailInfo['poolInformation'].IsHardCap ? `${poolDetailInfo["poolInformation"].HardCap} ${this.lpType}` : ''
      },
      {
        label: 'Access type',
        value: poolDetailInfo["poolInformation"].AccessType
      }
    ]
    this.tokenInfoList = [
      {
        label: 'Token Symbol',
        value: poolDetailInfo["tokenInformation"].TokenSymbol
      },
      {
        label: 'Network',
        value: poolDetailInfo["tokenInformation"].Network
      },
      {
        label: 'Token Address',
        value: poolDetailInfo["tokenInformation"].TokenAddress
      },
      {
        label: 'Total Supply',
        value: poolDetailInfo["tokenInformation"].TotalSupply
      },
      {
        label: 'Decimals',
        value: poolDetailInfo["tokenInformation"].Decimals
      }
    ]
    this.claimUrl = this.$store.state.remotePoolClaimInfo.find((pool) => {
      return pool.poolContractAddress === this.poolContract
    })?.claimUrl
    this.isLoading = false;
  },
  methods: {
    initSetPoolDetail() {
      this.poolName = sessionStorage.getItem('poolName')
      this.igoType = sessionStorage.getItem('igoType')
      this.poolContract = sessionStorage.getItem('poolContract')
      this.lpType = sessionStorage.getItem('lpType')
      this.lpToken = sessionStorage.getItem('lpToken')
      this.lpPrice = sessionStorage.getItem('lpPrice')
      if (sessionStorage.getItem('priceText')) {
        this.priceText = sessionStorage.getItem('priceText')
      } else {
        this.priceText = ''
      }
      this.hardCap = sessionStorage.getItem('hardCap')
      this.earnToken = sessionStorage.getItem('earnToken')
      this.earn = sessionStorage.getItem('earn')
      this.earnImg = sessionStorage.getItem('earnImg')
      this.isPoolRefund = eval(sessionStorage.getItem('isPoolRefund'))
      this.isLink = eval(sessionStorage.getItem('isLink'))
      this.isSureClaim = eval(sessionStorage.getItem('isSureClaim'))
      this.introduce = sessionStorage.getItem("introduce")
    },
    cutAccount(account, num) {
      return cutAccount(account, num);
    },
    onRefresh(accounts) {
      this.$store.commit('setState', {
        accounts
      });
      localStorage.setItem('accounts', accounts);
      if (this.isLoading) return;
      this.initPoolInfo()
    },
    async getIsOverRaising() {
      try {
        await metaMaskTools.getIsOverRaising(this.poolContract).then(async (data) => {
          this.isOverRaising = data;
          if (!this.isOverRaising) {
            this.getRemainingFundraising();
          }
        });
      } catch (e) {
        console.log(e)
      }
    },
    async getRemainingFundraising() {
      try {
        await metaMaskTools.getRemainingFundraising(this.poolContract).then(async (data) => {
          this.allowance = BigNumberDiv(parseFloat(data), 1e18);
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getPoolArray() {
      try {
        await metaMaskTools.getPoolAddressArray(this.poolContract).then(async (data) => {
          this.poolAddressArray = data
          if (this.poolAddressArray.length > 0) {
            this.getThresholdByIndex(this.poolAddressArray);
          }
        });
      } catch (e) {
        console.log(e)
      }
    },
    async getThresholdByIndex(pool) {
      try {
        this.poolThresholdArray = []
        for (let i = 0; i < pool.length; i++) {
          await metaMaskTools.getThresholdByIndex(this.poolContract, pool[i], 1).then(async (data) => {
            this.poolThresholdArray[i] = parseInt(BigNumberDiv(data, 1e18))
          });
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getIsPublic() {
      this.isPublic = await metaMaskTools.getIsPublic(this.poolContract)
    },
    openRefundTips() {
      if (!this.isClaim) {
        this.$message('This is not the time for a refund!');
        return
      }
      if (this.refundPeriod) {
        this.$message('The refund period has expired!');
        return
      }
      if (this.obtain <= 0) {
        this.$message('No refund amount!');
        return
      }

      this.$confirm('Do you confirm the refund?', 'Refund', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        this.claim(true)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Refund cancellation!'
        });
      });
    },
    updateRefundPeriod() {
      this.isRefundPeriod();
      setTimeout(() => {
        if (!this.refundPeriod && this.isClaim) {
          this.updateRefundPeriod()
        }
      }, 2000)
    },
    async getIsOpenClaim() {
      // 强制改成Airdrop的池子
      if (this.poolContract === "0xd6afb9afeeb10a5ac3b0015335e7d1f20c711979" ||
          this.poolContract === '0x9286c6144ceff2d106e98df45e0cabf4e5384c14' ||
          this.poolContract === '0x22fe70362632ee427f7f386d1ab49b3b616bbdd2' ||
          this.poolContract === '0x318b34ee3fbbe5c3363cfcd8ef04b029c1dd060f'
      ) {
        this.isOpenClaim = false;
        return;
      }
      try {
        await metaMaskTools.getIsOpenClaim(this.poolContract).then(async (data) => {
          this.isOpenClaim = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async isRefundPeriod() {
      try {
        await metaMaskTools.isRefundPeriod(this.poolContract).then(async (data) => {
          this.refundPeriod = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async coinAllowanceFundRaising() {
      try {
        await metaMaskTools.coinAllowanceFundRaising(this.lpToken, this.poolContract, this.accounts).then(async (data) => {
          this.owanceNum = parseFloat(data)
        });
      } catch (e) {
        console.log(e)
      }
    },
    async coinApproveFundRaising() {
      if (this.isAdmin) {
        this.$message('Administrators cannot participate!');
        return
      }
      if (this.isStart && !this.isEnd) {
        try {
          await metaMaskTools.coinApproveFundRaising(this.lpToken, this.poolContract, this.accounts).then(async (data) => {
            this.initPoolInfo()
          });
        } catch (e) {
          console.log(e)
        }
      } else {
        if (!this.isStart && !this.isEnd) {
          this.$message('The activity has not started yet!');
        }
        if (this.isStart && this.isEnd) {
          this.$message('The activity has ended!');
        }
      }
    },
    async getNumberParticipants() {
      try {
        await metaMaskTools.getNumberParticipants(this.poolContract).then(async (data) => {
          this.numberParticipants = parseFloat(data)
        });
      } catch (e) {
        console.log(e)
      }
    },
    async getExchangePoolDetails() {
      try {
        await metaMaskTools.getExchangePoolDetails(this.poolContract).then(async (data) => {
          if (this.poolContract === '0xB23C1293e17b8E3ba6CD69fA1122f736487C40c5') {
            this.lpPrice = parseFloat(data[9])
          } else {
            this.lpPrice = BigNumberDiv(parseFloat(data[9]), 1e18)
          }
          this.percentage = parseFloat(setDecimal(BigNumberDiv(data[10] * 100, data[7]), 3, false, false))
          this.totalAmountInvested = parseFloat(setDecimal(BigNumberDiv(data[10], 1e18), 6, false, false))
          this.hardCap = BigNumberDiv(parseFloat(data[7]), 1e18)
        });
      } catch (e) {
        console.log(e)
      }
    },
    async getExchangeInfo() {
      try {
        await metaMaskTools.getExchangeInfo(this.poolContract, this.accounts).then(async (data) => {
          this.proportion = parseFloat(setDecimal(data[0], 6, false, false)) // 个人兑换占比
          this.obtain = parseFloat(setDecimal(BigNumberDiv(data[1], 1e18), 6, false, false)) // (预计)获取
          this.exchange = parseFloat(setDecimal(BigNumberDiv(data[2], 1e18), 6, false, false)) // 兑换成功
          this.retrievable = parseFloat(setDecimal(BigNumberDiv(data[3], 1e18), 6, false, false)) // 可取回
          this.balance = parseFloat(setDecimal(BigNumberDiv(data[4], 1e18), 6, false, false)) // 余额
          this.quota = parseFloat(setDecimal(BigNumberDiv(data[5], 1e18), 6, false, false)) // 额度
          this.availableAuota = parseFloat(setDecimal(BigNumberDiv(data[6], 1e18), 6, false, false)) // 可用额度
          // this.percentage = parseFloat(setDecimal((this.quota - this.availableAuota) * 100 / this.quota, 6, false, false))
        });
      } catch (e) {
        console.log(e)
      }
    },
    initPoolInfo() {
      if (localStorage.getItem('accounts')) {
        this.accounts = localStorage.getItem('accounts')
      }
      if (this.accounts && this.accounts !== 'null') {
        this.coinAllowanceFundRaising()
        this.getExchangeInfo()
      }
      if (this.igoType === 'one') {
        this.getThreshold()
      } else {
        this.getPoolArray()
        this.getIsOverRaising()
        this.isRefundPeriod()
      }
      if (!this.isSureClaim) {
        this.getIsOpenClaim()
      }
      this.getNumberParticipants()
      this.getExchangePoolDetails()
      setTimeout(() => {
        if (this.accounts && this.accounts !== 'null') {
          this.isWhiteList()
          this.isThreshold()
          this.isAdminFundRaising()
          this.userIsClaim()
        }
        this.isStartFundRaising()
        this.isEndFundRaising()
        this.isClaimFundRaising()
        this.getIsPublic()
      }, 100)
    },
    setMax() {
      if (!this.isStart && !this.isEnd) {
        this.$message('The activity has not started yet!');
        return
      }
      if (this.isStart && this.isEnd) {
        this.$message('The activity has ended!');
        return
      }
      if (this.balance <= this.availableAuota) {
        this.amount = this.balance
      } else {
        this.amount = this.availableAuota
      }
    },
    async join() {
      if (this.isAdmin) {
        this.$message('Administrators cannot participate!');
        return
      }
      if (!this.isOverRaising) {
        if (this.allowance === 0) {
          this.$message('Fundraising is full!');
          return
        }
        if (Number(this.amount) > Number(this.allowance)) {
          this.$message('Can not exceed the remaining raised amount: ' + this.allowance + "!");
          return
        }
        if (Number(this.amount) > Number(this.availableAuota)) {
          this.$message(`Can not exceed your remaining allocation amount: ${this.availableAuota}`);
          return
        }
      }
      if (this.isStart && !this.isEnd) {
        try {
          if (!this.isPublic && (this.isStandard ? false : (parseInt(this.isWhite) === 0 || parseInt(this.isWhite) === 3))) {
            this.$message('This account is not a whitelist!');
            return
          }
          if (!this.amount) {
            this.$message('Please enter the amount!');
            return
          }
          if (this.amount === 0 || this.amount < 0) {
            this.$message('Amount cannot be less than or equal to 0!');
            return
          }
          if (this.balance <= this.availableAuota) {
            if (this.amount > this.balance) {
              this.$message('The available purchase amount of this account is insufficient!');
              return
            }
          } else {
            if (this.amount > this.availableAuota) {
              this.$message('The available purchase amount of this account is insufficient!');
              return
            }
          }
          // 如果有这个值，则要求投入数目是这个值的整数倍
          if (this.$store.state.poolDetails[this.poolName].tokenInformation.MinPricePerToken) {
            const amount = Number(this.amount)
            const minPrice = Number(this.$store.state.poolDetails[this.poolName].tokenInformation.MinPricePerToken);
            const times = Math.floor(amount / minPrice);
            if (times * minPrice !== amount) {
              this.$message(`The amount must be an integer multiple of token price: ${minPrice}`);
              return;
            }
          }
          await metaMaskTools.participateExchange(this.poolContract, this.accounts, this.amount).then(async (data) => {
            this.amount = null
            this.initPoolInfo();
          });
        } catch (e) {
          console.log(e)
        }
      } else {
        if (!this.isStart && !this.isEnd) {
          this.$message('The activity has not started yet!');
        }
        if (this.isStart && this.isEnd) {
          this.$message('The activity has ended!');
        }
      }
    },
    async isThreshold() {
      try {
        await metaMaskTools.isThreshold(this.poolContract, this.accounts).then(async (data) => {
          this.isStandard = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async getThreshold() {
      try {
        await metaMaskTools.getThreshold(this.poolContract).then(async (data) => {
          this.threshold = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async isStartFundRaising() {
      try {
        await metaMaskTools.isStartFundRaising(this.poolContract).then(async (data) => {
          this.isStart = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async isEndFundRaising() {
      try {
        await metaMaskTools.isEndFundRaising(this.poolContract).then(async (data) => {
          this.isEnd = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async remainingTime() {
      try {
        await metaMaskTools.remainingTime(this.poolContract, 0).then(async (data) => {
          this.poolRemainingTime = parseInt(BigNumberDiv(data, (60 * 60 * 24)))
        });
      } catch (e) {
        console.log(e)
      }
    },
    setPoolStatus() {
      this.isStartFundRaising()
      this.isEndFundRaising()

      setTimeout(() => {
        if (this.isStart && !this.isEnd) {
          this.poolStatus = 'OPEN'
        } else if (this.isStart && this.isEnd) {
          this.poolStatus = 'CLOSED'
        } else if (!this.isStart) {
          this.remainingTime()
          this.poolStatus = 'IN ' + this.poolRemainingTime + ' DAYS'
        }
        if (!this.isEnd) {
          setTimeout(() => {
            this.setPoolStatus()
          }, 2000)
        }
      }, 100)
    },
    async isClaimFundRaising() {
      try {
        await metaMaskTools.isClaimFundRaising(this.poolContract).then(async (data) => {
          this.isClaim = data
          if (this.isClaim && this.igoType === 'many') {
            this.updateRefundPeriod()
          }
        });
      } catch (e) {
        console.log(e)
      }
    },
    async isAdminFundRaising() {
      try {
        await metaMaskTools.isAdminFundRaising(this.poolContract, this.accounts).then(async (data) => {
          this.isAdmin = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async isWhiteList() {
      try {
        await metaMaskTools.isWhiteList(this.poolContract, this.accounts).then(async (data) => {
          this.isWhite = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async userIsClaim() {
      try {
        await metaMaskTools.userIsClaim(this.poolContract, this.accounts).then(async (data) => {
          this.isUserClaim = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async claim(isRefund) {
      try {
        if (this.poolContract === '0x6bBf952E24808c81f78F30aB0f76dE9062985066' && !isRefund) {
          return
        }
        if (isRefund) {
          if (!this.isClaim) {
            this.$message('This is not the time for a refund!');
            return
          }
          if (this.refundPeriod) {
            this.$message('The refund period has expired!');
            return
          }
        } else {
          if (!this.isClaim) {
            this.$message('It\'s not time to collect claim!');
            return
          }
        }

        if (this.isUserClaim) {
          this.$message('This account has received rewards!');
          return
        }
        if (this.obtain <= 0) {
          if (isRefund) {
            this.$message('No refund amount!');
          } else {
            this.$message('No claim!');
          }
          return
        }
        await metaMaskTools.claim(this.poolContract, this.accounts, this.igoType, isRefund).then(async (data) => {
          this.userIsClaim()
          this.initPoolInfo()
        });
      } catch (e) {
        console.log(e)
      }
    },
    async ownerClaim() {
      try {
        if (this.isUserClaim) {
          this.$message('You have received the reward!');
          return
        }
        await metaMaskTools.ownerClaim(this.poolContract, this.accounts).then(async (data) => {
          this.userIsClaim()
          this.initPoolInfo()
        });
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style lang="less">
.el-progress-bar__outer {
  background-color: transparent;
  border: 1px solid #FFFFFF;
}
</style>
<style lang="less" scoped>
.header {
  .pool-logo {
    width: 125px;
    height: 125px;
    background-size: cover;
    background-image: url("../../../public/images/igo/bg_pool_logo.png");
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 90%;
      border-radius: 50%;
    }
  }

  .pool-name {
    margin-left: 23px;
    font-weight: 700;
    font-size: 24px;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .contact-icon {
    height: 26px;
    margin-left: 32px;
  }
}

.info-panel {
  width: 103%;
  height: 284px;
  background-size: 100% 100%;
  background-image: url("../../../public/images/igo/bg_info_panel.png");
  padding: 0 3.8%;
  position: relative;
  left: -1.5%;

  .about-project-text {
    font-size: 18px;
    line-height: 34px;
    color: #FFFFFF;
    max-width: 80%;
  }

  .pool-remark {
    font-weight: 700;
    font-size: 24px;
    line-height: 104.5%;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    align-items: center;

    .label {
      height: 24px;
      background: #00DFFF;
      border-radius: 5px;
      font-size: 14px;
      color: #0C0530;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px;
      margin-left: 33px;
    }
  }

  .pool-desc {
    font-size: 14px;
    line-height: 141.25%;
    color: #FFFFFF;
    max-width: 600px;
  }

  a {
    height: 41px;
    background: #FB8F10;
    border-radius: 29.0239px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    padding: 0 16px;
  }

  .progress-panel {
    width: 488px;
    height: 203px;
    border: 1px solid #05F1A9;
    border-radius: 25px;
    padding: 26px 28px 0 22px;
    flex-shrink: 0;

    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 104.5%;
      text-transform: uppercase;
      color: #FFFFFF;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .horizontal-line {
      opacity: 0.3;
      border: 0.5px solid #FFFFFF;
    }

    .progress-title {
      font-size: 16px;
      line-height: 110%;
      color: #FFFFFF;
    }
  }
}

.main-panel {
  border: solid 0.5px #05F1A9;
  background: #0C0530;
  border-radius: 25px;
  padding: 0 45px 0 60px;
  font-size: 16px;
  line-height: 110%;
  color: #FFFFFF;

  .block-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 270px;
    background-color: rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: 700;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    z-index: 10;

    .tips {
      display: flex;
      align-items: center;
    }

    .why-btn {
      width: 30px;
      height: 30px;
      background-color: #FB8F10;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      cursor: help;
    }
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 104.5%;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
  }

  .amount-input {
    background: #0C0530;
    border: 1px solid #FFFFFF;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    width: 60%;
    padding: 0 20px;
    height: 40px;

    .max-btn {
      font-weight: 500;
      line-height: 110%;
      color: #FB8F10;
      cursor: pointer;
    }

    input {
      outline: none;
      background: none;
      border: none;
      height: 36px;
      width: 80%;
    }

    input::placeholder {
      font-size: 16px;
      line-height: 110%;
      color: #ffffff;
      opacity: 0.5;
    }
  }

  .act-btn-disabled {
    width: 128px;
    height: 41px;
    background-color: #e0e0e0;
    border-radius: 29.0239px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: not-allowed;
  }

  .act-btn-disabled.enabled {
    background: #FB8F10;
    cursor: pointer;
  }

  .token-to-claim {
    background: #07002D;
    width: 47px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    border-radius: 25px;
    font-weight: 700;
    font-size: 24px;
    color: #FB8F10;
  }
}

.detail-title {
  font-weight: 500;
  font-size: 28px;
  line-height: 62px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.detail-panel {
  width: 40%;
  height: 324px;
  background-image: url("../../../public/images/igo/bg_filled_panel.png");
  background-size: 100% 324px;
  padding-top: 36px;
  padding-left: 5%;
  padding-right: 5%;

  .title {
    font-weight: 600;
    font-size: 26px;
    line-height: 24px;
    color: #05F1A9;
    margin-bottom: 10px;
  }

  .info-row {
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.483732px solid rgba(#FFFFFF, 0.3);

    .label {
      font-size: 18px;
      color: #FFFFFF;
    }

    .value {
      font-size: 18px;
      color: #FB8F10;
    }
  }

  .info-row:last-child {
    border-bottom: none;
  }
}

.schedule-row {
  border-top: 0.483732px solid rgba(#FFFFFF, 0.3);
  height: 94px;
  display: flex;
  align-items: center;

  .row-one {
    font-size: 18px;
    color: #FFFFFF;
    width: 30%;
  }

  .row-opens {
    font-size: 18px;
    color: #FB8F10;
    width: 40%;
  }
}
</style>
<style lang="less" scoped>
.m-pool-logo {
  margin: 0 auto;
  width: 1.25rem;
  height: 1.25rem;
  background-size: cover;
  background-image: url("../../../public/images/igo/bg_pool_logo.png");
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 90%;
    border-radius: 50%;
  }
}

.m-pool-name {
  font-weight: 700;
  font-size: 0.24rem;
  line-height: 0.25rem;
  text-transform: uppercase;
  color: #FFFFFF;
  text-align: center;
}

.m-contact-icon {
  height: 0.21rem;
  margin-left: 0.25rem;
}

.m-info-panel {
  width: 3.59rem;
  background-size: 100% 100%;
  background-image: url("../../../public/images/igo/mobile/bg_info_panel.png");
  padding: 0 0.48rem;
  text-align: center;

  .about-project-text {
    width: 2.53rem;
    font-size: 0.14rem;
    line-height: 0.20rem;
    color: #FFFFFF;
  }

  .pool-remark {
    font-weight: 700;
    font-size: 0.2rem;
    line-height: 104.5%;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .label {
      height: 0.24rem;
      background: #00DFFF;
      border-radius: 5px;
      font-size: 0.14rem;
      color: #0C0530;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.12rem;
    }
  }

  .pool-desc {
    font-size: 0.14rem;
    line-height: 141.25%;
    color: #FFFFFF;
  }

  a {
    margin: 0 auto;
    width: 2.35rem;
    height: 0.41rem;
    background: #FB8F10;
    border-radius: 29.0239px;
    font-weight: 500;
    font-size: 0.16rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .progress-panel {
    width: 2.65rem;
    height: 1.71rem;
    margin: 0 auto;
    border: 1px solid #05F1A9;
    border-radius: 0.17rem;
    padding: 0 0.11rem;
    text-align: left;

    .title {
      font-weight: 700;
      font-size: 0.14rem;
      line-height: 104.5%;
      text-transform: uppercase;
      color: #FFFFFF;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .horizontal-line {
      opacity: 0.3;
      border: 0.5px solid #FFFFFF;
    }

    .progress-title {
      font-size: 0.12rem;
      line-height: 110%;
      color: #FFFFFF;
    }
  }
}

.m-main-panel {
  width: 3.02rem;
  margin: 0 auto;
  border: solid 0.5px #05F1A9;
  background: #0F033F;
  border-radius: 25px;
  font-size: 0.16rem;
  line-height: 110%;
  color: #FFFFFF;

  .block-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3.55rem;
    background-color: rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.18rem;
    font-weight: 700;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    z-index: 10;

    .tips {
      display: flex;
      align-items: center;
    }

    .why-btn {
      width: 0.3rem;
      height: 0.3rem;
      background-color: #FB8F10;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.12rem;
      cursor: help;
    }
  }

  .title {
    margin: 0 auto;
    width: 2.38rem;
    font-weight: 700;
    font-size: 0.18rem;
    line-height: 104.5%;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    height: 0.47rem;
  }

  .text {
    font-size: 16px;
    line-height: 110%;
    color: white;
    text-align: center;
  }

  .amount-input {
    background: #0C0530;
    border: 1px solid #FFFFFF;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 2.83rem;
    height: 0.46rem;
    text-align: center;

    input {
      outline: none;
      background: none;
      border: none;
      height: 0.36rem;
      width: 80%;
    }

    input::placeholder {
      font-size: 0.14rem;
      line-height: 110%;
      color: #ffffff;
      opacity: 0.5;
    }
  }

  .max-btn {
    font-weight: 500;
    line-height: 110%;
    font-size: 0.16rem;
    color: #FB8F10;
    cursor: pointer;
  }

  .act-btn-disabled {
    width: 1.28rem;
    height: 0.41rem;
    background-color: #e0e0e0;
    border-radius: 29.0239px;
    font-size: 0.16rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: not-allowed;
    margin: 0 auto;
  }

  .act-btn-disabled.enabled {
    background: #FB8F10;
    cursor: pointer;
  }

  .token-to-claim {
    background: #07002D;
    width: 0.47rem;
    height: 0.47rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.15rem;
    border-radius: 25px;
    font-weight: 700;
    font-size: 0.24rem;
    color: #FB8F10;
  }
}

.m-detail-title {
  font-weight: 500;
  font-size: 0.24rem;
  line-height: 0.62rem;
  text-transform: uppercase;
  color: #FFFFFF;
  text-align: center;
}

.m-detail-panel {
  margin: 0 auto;
  width: 3.3rem;
  background-image: url("../../../public/images/igo/bg_filled_panel.png");
  background-size: 100% 100%;
  padding: 0.38rem 0.3rem 0.18rem 0.25rem;

  .title {
    font-weight: 600;
    font-size: 0.14rem;
    line-height: 0.13rem;
    color: #05F1A9;
    margin-bottom: 0.05rem;
  }

  .info-row {
    height: 0.27rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.483732px solid rgba(#FFFFFF, 0.3);

    .label {
      font-size: 0.1rem;
      color: #FFFFFF;
    }

    .value {
      font-size: 0.1rem;
      color: #FB8F10;
    }
  }

  .info-row:last-child {
    border-bottom: none;
  }
}

.m-schedule-row {
  border-top: 0.483732px solid rgba(#FFFFFF, 0.3);
  display: flex;
  align-items: center;
  width: 3.09rem;
  margin: 0 auto;
  padding-top: 0.08rem;

  .row-one {
    font-size: 0.12rem;
    color: #FFFFFF;
    width: 30%;
  }

  .row-opens {
    font-size: 0.12rem;
    color: #FB8F10;
    width: 40%;
  }
}
</style>
